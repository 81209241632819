<app-header></app-header>

<div>
    
    <!--Inicio Banner DGME-->
    <section>
       <div >
          <img src="./assets/Banner-Header-DGME-2.png" class="img-fluid" alt="Wild Landscape" />
        </div>
    </section>
    <!--Fin Banner DGME-->

    <!-- Inicio Título DGME y Descripción -->
    <div class="container mt-5 mb-5 text-center">
        <h1><b>DIRECCIÓN GENERAL DE MATERIALES EDUCATIVOS</b></h1>
        <h5 class="mt-3">
            Los materiales educativos han sido protagonistas de la historia de la educación pública en México al contribuir a formar a las generaciones de
            mexicanos. Somos el área encargada de actualizar tanto los contenidos, como la función y uso de los Libros de Texto Gratuitos y acercar a la
            escuela una diversidad de materiales educativos (en soportes físicos y digitales), así como mejorar el acervo de las bibliotecas escolares.
        </h5>
    </div>
    <!-- Fin Título DGME y Descripción -->

    <!-- Inicio Sección Nueva familia de textos gratuitos -->
    <div class="container mt-5 mb-5 text-left">
      <!-- <h3 class="subrayado"><b>Nueva Familia de Textos Gratuitos</b></h3> -->
      <!-- Inicio Carrusel LTG -->
      <div class="container mt-5">
        <mdb-carousel class="mb-5 carousel slide carousel-fade"  [animation]="'fade'">
          <mdb-carousel-item *ngFor="let item of carrusel" style="border-radius: 4%;">
              <a *ngIf="item.url_carrusel" href="{{item.url_carrusel}}" target="{{item.redireccionamiento == 1 ? '_blank' : '_self'}}">
                  <img class="d-block w-100" src="{{item.img_carrusel}}" alt="First slide">
              </a>
              <img *ngIf="!item.url_carrusel" class="d-block w-100" src="{{item.img_carrusel}}" alt="{{item.img_carrusel}}">
          </mdb-carousel-item>   
      </mdb-carousel>
      </div>
      <!-- Fin Carrusel LTG -->
  </div>
  <!-- Fin Sección Nueva familia de textos gratuitos -->
  <div class="container mt-5 mb-5 text-left">
    <h3><b>Convocatorias</b></h3>
    <hr>
    <!-- Inicio Carrusel Convocatorias -->
    <div class="container mt-5">
      <mdb-carousel class="mb-5 carousel slide carousel-fade" [animation]="'fade'">
        <mdb-carousel-item *ngFor="let item of card" style="border-radius: 4%;">
            <a *ngIf="item.url_pag" href="{{item.url_pag}}" target="{{item.redireccionamiento == 1 ? '_blank' : '_self'}}">
                <img class="d-block w-100" src="{{item.img}}" alt="First slide">
            </a>
            <img *ngIf="!item.url_pag" class="d-block w-100" src="{{item.img}}" alt="{{item.img_carrusel}}" (click)="openModal(item.titulo, item.descripcion, item.fecha_inicio, item.fecha_fin)">
        </mdb-carousel-item>   
    </mdb-carousel>
    </div>
    <!-- Fin Carrusel Convocatorias -->
  </div>
  

    <!-- Inicio Sección Direcciones-->
    <div class="container mt-5 mb-5 text-left">
        <h3><b>Direcciones</b></h3>
        <hr>
        
        <section class="text-center mt-5">
            <!--Grid row-->
            <div class="row">
              <!--Grid column-->
              <div class="col-md-3">
                <img src="./assets/direcciones/1.png" class="img-fluid z-depth-1 rounded-circle" alt="Responsive image">
                <h5 class="grey-text mt-3">
                  Dirección de Administración de Recursos y de Gestión Digital
                </h5>
              </div>
              <!--Grid column-->

              <!--Grid column-->
              <div class="col-md-3">
                <img src="./assets/direcciones/2.png" class="img-fluid z-depth-1 rounded-circle" alt="Responsive image">
                <h5 class="grey-text mt-3">
                  Dirección de Planeación y Seguimiento
                </h5>
              </div>
              <!--Grid column-->

              <!--Grid column-->
              <div class="col-md-3">
                <img src="./assets/direcciones/3.png" class="img-fluid z-depth-1 rounded-circle" alt="Responsive image">
                <h5 class="grey-text mt-3">
                  Dirección Editorial
                </h5>
              </div>
              <!--Grid column-->

              <!--Grid column-->
              <div class="col-md-3">
                <img src="./assets/direcciones/4.png" class="img-fluid z-depth-1 rounded-circle" alt="Responsive image">
                <h5 class="grey-text mt-3">
                  Dirección de Bibliotecas y Promoción de la Lectura
                </h5>
              </div>
              <!--Grid column-->

              <!--Grid column-->
              <div class="col-md-3">
                <img src="./assets/direcciones/5.png" class="img-fluid z-depth-1 rounded-circle" alt="Responsive image">
                <h5 class="grey-text mt-3">
                  Dirección de Evaluación y Distribución
                </h5>
              </div>
              <!--Grid column-->

              <!--Grid column-->
              <div class="col-md-3">
                <img src="./assets/direcciones/6.png" class="img-fluid z-depth-1 rounded-circle" alt="Responsive image">
                <h5 class="grey-text mt-3">
                  Dirección de Medios Audiovisuales e Informáticos
                </h5>
              </div>
              <!--Grid column-->

              <!--Grid column-->
              <div class="col-md-3">
                <img src="./assets/direcciones/7.png" class="img-fluid z-depth-1 rounded-circle" alt="Responsive image">
                <h5 class="grey-text mt-3">
                  Dirección de Desarrollo e Innovación de Materiales Educativos
                </h5>
              </div>
              <!--Grid column-->

            </div>
            <!--Grid row-->
        </section>
        <!--Section: Features v.1-->


    </div>
    <!-- Fin Sección Direcciones-->

    <!-- Inicio Sección Directorio-->
    <div class="container mt-5 mb-5 text-left">
        <h3><b>Directorio</b></h3>
        <hr>
        <div class="row container " >
          <div class="col-md-2 mt-5" style="text-align: center;">
            <img src="./assets/directores/marx.png" class="img-fluid z-depth-1 mb-4 " style="border-radius: 20px;" alt="">
          </div>
          <div class="col-md-2 mt-5 :left">
            <h5><b>Marx Arriaga Navarro</b> </h5>
            <h6><b>Director General de Materiales Educativos</b> </h6>
          </div>
          <div class="col-md-2 mt-5" style="text-align: center;">
            <img src="./assets/directores/carolina.png" class="img-fluid z-depth-1 mb-4 " style="border-radius: 20px;" alt="">
          </div>
          <div class="col-md-2 mt-5 :left">
            <h5><b>Carolina Lizeth Sosa Hurtado</b> </h5>
            <h6><b>Directora de Planeación y Seguimiento</b> </h6>
          </div>
          <div class="col-md-2 mt-5" style="text-align: center;">
            <img src="./assets/directores/betzaida.png" class="img-fluid z-depth-1 mb-4 " style="border-radius: 20px;" alt="">
          </div>
          <div class="col-md-2 mt-5 :left">
            <h5><b>Betsaida Zuñiga Hernández</b> </h5>
            <h6><b>Directora de Administración de Recursos y de Gestión Digital</b> </h6>
          </div>
          <div class="col-md-2 mt-5" style="text-align: center;">
            <img src="./assets/directores/alexportilla.png" class="img-fluid z-depth-1 mb-4 " style="border-radius: 20px;" alt="">
          </div>
          <div class="col-md-2 mt-5 :left">
            <h5><b>Alejandro Portilla de Buen</b> </h5>
            <h6><b>Director Editorial</b> </h6>
          </div>
          <div class="col-md-2 mt-5" style="text-align: center;">
            <img src="./assets/directores/avatar.png" class="img-fluid z-depth-1 mb-4 " style="border-radius: 20px;" alt="">
          </div>
          <div class="col-md-2 mt-5 :left">
            <h5><b></b> </h5>
            <h6><b>Director de Bibliotecas y Promoción de la Lectura</b> </h6>
          </div>
          <div class="col-md-2 mt-5" style="text-align: center;">
            <img src="./assets/directores/noe.png" class="img-fluid z-depth-1 mb-4 " style="border-radius: 20px;" alt="">
          </div>
          <div class="col-md-2 mt-5 :left">
            <h5><b>Noé García Ortíz</b> </h5>
            <h6><b>Director de Evaluación y Distribución</b> </h6>
          </div>
          <div class="col-md-2 mt-5" style="text-align: center;">
            <img src="./assets/directores/luismiguel.png" class="img-fluid z-depth-1 mb-4 " style="border-radius: 20px;" alt="">
          </div>
          <div class="col-md-2 mt-5 :left">
            <h5><b>Luis Miguel Cisneros Villanueva</b> </h5>
            <h6><b>Director de Medios Audiovisuales e Informáticos</b> </h6>
          </div>
          <div class="col-md-2 mt-5" style="text-align: center;">
            <img src="./assets/directores/sady.png" class="img-fluid z-depth-1 mb-4 " style="border-radius: 20px;" alt="">
          </div>
          <div class="col-md-2 mt-5 :left">
            <h5><b>Sady Arturo Loaiza Escalona</b> </h5>
            <h6><b>Director de Desarrollo e Innovación de Materiales Educativos</b> </h6>
          </div>
          
        
        

        </div>


    </div>
    <!-- Fin Sección Directorio-->

    <!-- Inicio Sección Contacto-->
    <div class="container mt-5 mb-5 text-left">
        <h3><b>Contacto</b></h3>
        <hr>
        <div class="row container " >

          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-centered ">
            <div class="row" style="margin-bottom: 2%">
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 text-centered ">
                    <p class="t-justified t-base-color" style="margin-top: 6%; font-size: 18px">Av. Universidad 1200, Colonia, Xoco.<br> Delegación. Benito Juárez, Ciudad de México. 03330 <br>Secretaría de Educación Pública</p>
                    <div class="row">
                    </div>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12 text-centered ">
                    <div class="form-control small-bottom-buffer" id="googleMap" style="width:100%;height:440px;"><iframe style="width: 100%; height: 426px;" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3764.1646875504366!2d-99.17098388509442!3d19.36202048692443!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff954448d6c7%3A0x9890bb16106aa670!2sAv.+Universidad+1200%2C+Xoco%2C+03330+Ciudad+de+M%C3%A9xico%2C+CDMX!5e0!3m2!1ses!2smx!4v1522865365326" frameborder="0" allowfullscreen=""></iframe></div>
                </div>
            </div>
        </div>
        </div>
    </div><i class="fas fa-old-republic    "></i>
    
    
 </div>

<app-footer></app-footer>
