import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConvocatoriasService {

  constructor( private http: HttpClient) { }
  //private url= 'http://localhost/portaldgme_2023/convocatoria/';
  private url= 'https://dgme.sep.gob.mx/php/convocatoria/';

  getConvocatoria(){
    return this.http.get(
      `${this.url}lib_convocatoria.php`
    );
  }

}
