<div class="modal-header  md_custom ">
  
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true" style="color: #DDDDDD;">×</span>
    </button>
    <h3 class="modal-title w-100 text-center  mt-4 font-weight-bold" id="myModalLabel">{{ title }}</h3>
  </div>

<!-- Info -->
<div class="container md_con_custom">
  <section class="my-5" aling="justify">
    <h6> <strong>Inicio:</strong> {{fecha_ini}} <br> <strong>Fecha Final:</strong> {{fecha_fin}}</h6>
    <br>
    <p> <strong> Descripción:</strong> {{descripcion}} </p>
  </section>
</div>

<!-- Footer -->
<div class="modal-footer">
  <p>
  <button type="button" class="btn" (click)="modalRef.hide()" style="background-color: #545454;">Cerrar</button>
  </p>
</div>
