import { Component } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-modal-c',
  templateUrl: './modal-c.component.html',
  styleUrls: ['./modal-c.component.scss']
})
export class ModalCComponent {
  title: string | null = null;
  descripcion: string | null = null;
  fecha_ini: string | null = null;
  fecha_fin: string | null = null;
  
  constructor(public modalRef: MDBModalRef) { }

  ngOnInit(): void {
    //console.log(this.url);
  }
}
