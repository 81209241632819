<!-- Footer -->
<footer class="page-footer font-small special-color-dark ">

    <!-- Footer Elements -->
    
    <!-- Footer Elements -->

    <!-- Copyright -->
    <div class="footer-copyright text-center py-3">© 2023 Copyright:
        <a href="#">Dirección General de Materiales Educativos</a>
    </div>
    <!-- Copyright -->

</footer>
<!-- Footer gob -->

<footer class="main-footer2">
    <div class="list-info">
        <div class="container">
            <div class="row">
                <div class="col-sm-3"><img data-v-9e928f9a="" src="https://framework-gb.cdn.gob.mx/landing/img/logoheader.svg" href="/" alt="logo gobierno de méxico" class="logo_footer" style="max-width: 90%;"></div>
                <div class="col-sm-3">
                    <h5>Enlaces</h5>
                    <ul>
                        <li><a href="https://participa.gob.mx" target="_blank" rel="noopener" title="Enlace abre en ventana nueva">Participa</a></li>
                        <li><a href="https://www.gob.mx/publicaciones" target="_blank" rel="noopener" title="Enlace abre en ventana nueva">Publicaciones Oficiales</a></li>
                        <li><a href="http://www.ordenjuridico.gob.mx" target="_blank" rel="noopener" title="Enlace abre en ventana nueva">Marco Jurídico</a></li>
                        <li><a href="https://consultapublicamx.inai.org.mx/vut-web/" target="_blank" rel="noopener" title="Enlace abre en ventana nueva">Plataforma Nacional de Transparencia</a></li>
                    </ul>
                </div>
                <div class="col-sm-3">
                    <h5>¿Qué es gob.mx?</h5>
                    <p>Es el portal único de trámites, información y participación ciudadana. <a href="https://www.gob.mx/que-es-gobmx">Leer más</a></p>
                    <ul>
                        <li><a href="https://datos.gob.mx">Portal de datos abiertos</a></li>
                        <li><a href="https://www.gob.mx/accesibilidad">Declaración de accesibilidad</a></li>
                        <li><a href="https://www.gob.mx/privacidadintegral">Aviso de privacidad integral</a></li>
                        <li><a href="https://www.gob.mx/privacidadsimplificado">Aviso de privacidad simplificado</a></li>
                        <li><a href="https://www.gob.mx/terminos">Términos y Condiciones</a></li>
                        <li><a href="https://www.gob.mx/terminos#medidas-seguridad-informacion">Política de seguridad</a></li>
                        <li><a href="https://www.gob.mx/sitemap">Mapa de sitio</a></li>
                    </ul>
                </div>
                <div class="col-sm-3">
                    <h5><a href="https://www.gob.mx/tramites/ficha/presentacion-de-quejas-y-denuncias-en-la-sfp/SFP54">Denuncia contra servidores públicos</a></h5>
                    <h5>Síguenos en</h5>
                    <ul class="list-inline ">
                        <li>
                            <a class="social-icon facebook float-left" style="color:#ffff;" target="_blank" rel="noopener" title="Enlace abre en ventana nueva" href="https://www.facebook.com/gobmexico" aria-label="Facebook de presidencia">
                                <mdb-icon fab icon="facebook-f"></mdb-icon>
                            </a>
                        </li>
                        <li>
                            <a class="social-icon twitter float-left px-3" style="color:#ffff;" target="_blank" rel="noopener" title="Enlace abre en ventana nueva" href="https://twitter.com/GobiernoMX" aria-label="Twitter de presidencia">
                                <mdb-icon fab icon="twitter"></mdb-icon>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid footer-pleca2">
        <div class="row">
            <div class="col"><br><br></div>
        </div>
    </div>
</footer>