import { Component, OnInit } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { ModalCComponent} from '../home/modal-c/modal-c.component';
import { CarruselService } from '../services/carrusel.service';
import {ConvocatoriasService} from '../services/convocatorias.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit{

  carrusel: any = [];
  convocatoria: any = [[]];
  card: any = [];
  modalRef: MDBModalRef | null = null;

  constructor(private _carrusel:CarruselService, private _convocatoria: ConvocatoriasService, private _modalService: MDBModalService) { }


  chunk(arr: any, chunkSize: number) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }

  ngOnInit(): void {

    //Servicio para llenar carrusel principal
    this._carrusel.getCarrusel().subscribe (res =>{
      this.carrusel = res;
      //console.log(this.Carrusel);
    },err =>{
      console.log(err);
    });
  
    //Servicio para llenar el carrusel de convocatorias
    this._convocatoria.getConvocatoria().subscribe( res1 => {
      this.card = res1;
     //console.log(this.card);
     this.convocatoria = this.chunk(this.card, 2);
     //console.log(this.convocatoria);
    },err =>{
      console.log(err);
    });
  }

  openModal( tit, desc, fechaIni, fechaFin) {
    console.log(tit);
    this.modalRef = this._modalService.show(ModalCComponent, {
      data: { title: tit, descripcion: desc, fecha_ini: fechaIni, fecha_fin: fechaFin},
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: 'modal-lg modal-top modal-dialog-centered',
      containerClass: 'top',
      animated: true
    });
  }
  


  }
